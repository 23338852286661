import { useRouter } from 'next/router';
import React, { useRef } from 'react';
import { toClient } from '@peloton/api/NextClient';
import {
  ALLOW_DATE_TRIGGER_PANEL,
  ALLOW_FEATURE_TOGGLES,
  ALLOW_QUERY_TOGGLES,
  OPTIMIZELY_DATAFILE,
  OPTIMIZELY_KEY,
  USE_APOLLO_V3,
} from '@peloton/app-config';
import LogoutCookieProvider from '@peloton/auth/LogoutCookieProvider';
import UserInfoProvider from '@peloton/auth/UserInfoProvider';
import { currentHostname } from '@peloton/browser';
import environmentFlags from '@peloton/env/environmentFlags';
import toApiEnv from '@peloton/env/toApiEnv';
import { toDigitalLinkEnv } from '@peloton/env/toDigitalLinkEnv';
import toLinkEnv from '@peloton/env/toLinkEnv';
import toPreorderLinkEnv from '@peloton/env/toPreorderLinkEnv';
import { ErrorReporterContext, configureErrorHandler } from '@peloton/error-reporting';
import {
  consoleErrorReporter,
  datadogBrowserErrorReporter,
} from '@peloton/error-reporting/reporters';
import { ExtLinkEnvProvider } from '@peloton/external-links/context/Provider';
import { toBookingLinkEnv, toExtLinkEnv } from '@peloton/external-links/models';
import { LocaleCookieProvider } from '@peloton/internationalize/LocaleCookieProvider';
import LinkComponentProvider from '@peloton/links/LinkComponentProvider';
import { NextDraftModeProvider } from '@peloton/next/components/NextDraftModeProvider';
import { SplitTestingProvider } from '@peloton/split-testing/hooks/provider';
import { ApolloProviderV3 } from '@peloton/with-apollo/v3/withApollo';
import toAccountLinkEnv from '@account/env/toAccountLinkEnv';
import GlobalReferenceProvider from '@acme-ui/global/GlobalReferenceProvider';
import GlobalUiStateProvider from '@acme-ui/global/GlobalUiStateProvider';
import TrackingProvider from '@ecomm/analytics/TrackingProvider';
import NextClientProvider from '@ecomm/api/NextClientProvider';
import ContextFeatureTogglePanel from '@ecomm/bootstrapping/feature-toggles/ContextFeatureTogglePanel';
import features from '@ecomm/bootstrapping/features';
// eslint-disable-next-line custom/no-restricted-imports
import datafiles from '@ecomm/bootstrapping/features/static';
import { CartProvider } from '@ecomm/cart-next/context/CartContext';
import DateTriggerPanel from '@ecomm/cms-promos/DateTriggerPanel';
import DateTriggerProvider from '@ecomm/cms-promos/DateTriggerProvider';
import {
  CommercetoolsClientProvider,
  toCommercetoolsClient,
} from '@ecomm/commercetools/apollo';
import { ToggleProvider } from '@ecomm/feature-toggle/context/ToggleContext';
import {
  computeToggles,
  getNextQueryToggles,
} from '@ecomm/feature-toggle/models/Toggles';
import type { DataFiles } from '@ecomm/feature-toggle/optimizely/client';
import { toFeatures } from '@ecomm/feature-toggle/optimizely/client';
import { CitizensProvider } from '@ecomm/financing/citizens';
import OAuthProvider from '@ecomm/oauth/OAuthProvider';
import type { AvailabilityByProduct } from '@ecomm/product-states/models/context';
import { ProductStatesProvider } from '@ecomm/product-states/NextProvider';
import { NextProgramProvider as ProgramProvider } from '@ecomm/programs/NextProvider';
import toStudioLinkEnv from '@studio/env/toLinkEnv';
import CustomSWRConfig from '../components/CustomSWRConfig';
import { useLocale } from '../hooks/useLocale';
import { PreviousRouteProvider } from './PreviousRouteProvider';

type Props = {
  productState: AvailabilityByProduct;
  fallback: any;
  buildTime: number;
  locale: string;
};

const Providers: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  productState,
  fallback,
  buildTime,
  locale: contextLocale,
}) => {
  const router = useRouter();
  const errorHandler = configureErrorHandler([
    consoleErrorReporter,
    datadogBrowserErrorReporter,
  ]);
  const routerLocale = useLocale();

  const locale = contextLocale ?? routerLocale;
  const computedToggles = computeToggles(
    features[locale],
    ALLOW_FEATURE_TOGGLES,
    ALLOW_FEATURE_TOGGLES,
  );
  const optimizelyFeatures = toFeatures(
    { locale, isEcommTester: false, email: '', queryParams: router.query },
    datafiles,
    (OPTIMIZELY_DATAFILE as keyof DataFiles) || 'production',
  );
  const queryToggles = getNextQueryToggles(ALLOW_QUERY_TOGGLES, router.query);

  const toggles = {
    ...computedToggles,
    ...optimizelyFeatures,
    ...queryToggles,
  };

  const persistingClient = useRef(toClient({})).current;

  // TODO: This should be dynamic because this component is shared across multiple apps
  const envFlags = environmentFlags('www')();
  const apiEnv = toApiEnv(currentHostname, envFlags);
  const nonLocalAppEnv = { ...envFlags, isLocal: false };
  const extLinkEnv = toExtLinkEnv({
    www: toLinkEnv(envFlags),
    ecomm: toLinkEnv(envFlags),
    digital: toDigitalLinkEnv(envFlags, apiEnv),
    api: apiEnv,
    preorder: toPreorderLinkEnv(envFlags),
    studio: toStudioLinkEnv(envFlags),
    account: toAccountLinkEnv(nonLocalAppEnv),
    booking: toBookingLinkEnv(envFlags),
  });

  return (
    <>
      <ErrorReporterContext.Provider value={{ errorReporter: errorHandler }}>
        <NextDraftModeProvider>
          <CustomSWRConfig fallback={fallback}>
            <SplitTestingProvider
              pathname={router.pathname}
              optimizelyProjectId={OPTIMIZELY_KEY}
            >
              <LinkComponentProvider>
                <LogoutCookieProvider>
                  <LocaleCookieProvider>
                    <ExtLinkEnvProvider extLinkEnv={extLinkEnv}>
                      <OAuthProvider>
                        <NextClientProvider client={persistingClient}>
                          <ApolloProviderV3 useApolloV3={USE_APOLLO_V3} locale={locale}>
                            <CommercetoolsClientProvider client={toCommercetoolsClient()}>
                              <UserInfoProvider>
                                <TrackingProvider>
                                  <ProgramProvider>
                                    <ToggleProvider features={{ ...toggles }}>
                                      <CitizensProvider>
                                        <ProductStatesProvider
                                          productState={productState}
                                        >
                                          <CartProvider>
                                            <GlobalReferenceProvider>
                                              <GlobalUiStateProvider>
                                                <DateTriggerProvider
                                                  buildTime={buildTime}
                                                >
                                                  <PreviousRouteProvider>
                                                    {children}
                                                  </PreviousRouteProvider>
                                                  <ContextFeatureTogglePanel />
                                                  {ALLOW_DATE_TRIGGER_PANEL && (
                                                    <DateTriggerPanel />
                                                  )}
                                                </DateTriggerProvider>
                                              </GlobalUiStateProvider>
                                            </GlobalReferenceProvider>
                                          </CartProvider>
                                        </ProductStatesProvider>
                                      </CitizensProvider>
                                    </ToggleProvider>
                                  </ProgramProvider>
                                </TrackingProvider>
                              </UserInfoProvider>
                            </CommercetoolsClientProvider>
                          </ApolloProviderV3>
                        </NextClientProvider>
                      </OAuthProvider>
                    </ExtLinkEnvProvider>
                  </LocaleCookieProvider>
                </LogoutCookieProvider>
              </LinkComponentProvider>
            </SplitTestingProvider>
          </CustomSWRConfig>
        </NextDraftModeProvider>
      </ErrorReporterContext.Provider>
    </>
  );
};

export default Providers;
